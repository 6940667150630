<template>
  <div class="work-page nike-play-gold">
  	<!-- Header -->
  	<section class="header">

      <img v-lazy="'/static/images/PG_1.png'"/>
    
  		<div class="meta" style="margin-top: -9%;">
  			<p class="client">NIKE</p>
  			<a class="project">PLAY GOLD</a>
  		</div>
  		<div class="copy container">
  			<div class="col-1">
  				<h1>Inspire a nation to play gold.</h1>
  			</div>
  			<div class="col-2">
  				<p>When we play free of fear, free of pressure and restriction, we can topple kings. The launch of the new World Cup kit for the Socceroos.</p>
  			</div>
  		</div>
  	</section>

    <div class="spacer-lg"></div>

    <div class="two-col" style="margin-top: 10%;">
      <div class="col" style="width: 60%; margin-top: 8%">
        <img src="/static/images/PG_2.jpg"/>
      </div>
      <div class="col" style="width: 40%; margin-left: -6%; z-index: 2;">
        <img src="/static/images/PG_3.jpg" style="margin-top:-200%;"/>
      </div>
    </div>

    <div class="spacer-lg"></div>

    <img src="/static/images/PG_4.jpg"/>

    <div class="spacer-lg"></div>

    <img src="/static/images/PG_5.jpg" style="width: 75%; position: relative; z-index: 2"/>

    <div style="width: 100%;">
      <img src="/static/images/PG_6.jpg" style="width: 45%; margin-top: -15%; float: right"/>
    </div>

    <div class="clear"></div>

    <div class="spacer-lg"></div>

    <img src="/static/images/PG_7.jpg" style="width: 75%;"/>

    <div style="width: 100%;">
      <img src="/static/images/PG_8.jpg" style="width: 33%; margin-top: -15%; position: relative; z-index: 2; float: right"/>
    </div>

    <div class="clear"></div>

    <div class="spacer-lg"></div>

    <img src="/static/images/PG_9.jpg"/>

    <div class="spacer-lg"></div>

    <div class="standout-copy" style="text-align: left; width: 100%;">
      If you have something to say, paint it big on a wall!
    </div>

    <div class="spacer-lg"></div>

    <img src="/static/images/PG_10.jpg" style="width: 70%; float: right"/>

    <div class="clear"></div>

    <div class="spacer-lg"></div>

    <img src="/static/images/PG_11.jpg"/>

    <div class="spacer-lg"></div>

    <img src="/static/images/nike-play-gold-12.png"/>


    <div class="footer">
      <div class="prev">
        <router-link to="/nike-mercurial">
          <img src="/static/images/prev.svg"/>
        </router-link>
      </div>
      <div class="footer-contact">
        <a href="mailto:grant@bloodutd.com,david@bloodutd.com">Drop us a line</a>
        <span>&copy; {{date}} by Blood UTD</span>
      </div>
      <div class="next">
        <router-link to="/nike-all-whites">
          <img src="/static/images/next.svg"/>
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'nike-play-gold',
  data() {
    return {
      date: new Date().getFullYear(),
    }
  }
}
</script>

<style lang="scss" scoped>

.nike-play-gold {

/*  .copy container {
    .col-1 {
      width: 55%;
    }
  }
*/
  .no-negative-top-mobile {
    @media(max-width: 680px) {
      
      .col:first-child {
         width: 39% !important;
         z-index: 2 !important;
        img {
          width: 60% !important;
          margin-right: -10% !important;
          margin-top: 10% !important;
        }
      }

      .col:last-child {
        width: 59% !important;
      } 
    }
  }

  .footer {
    padding-top: 40px;

     @media(max-width: 680px) {
        padding-top: 20px;
     }
  }

}

</style>
